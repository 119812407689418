<template>
    <div v-if="authUserPermission['kpi-mcr-creation-view']">
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>
            </div>
        </base-header>
        <div class="container-fluid mt--6 header-body">
            <div class="card mb-4">
                <div class="card-header">
                    <div class="row">
                        <div class="col">
                            <h3 class="mb-0">{{ tt('kpi_mcr_creation') }}</h3>
                        </div>
                        <div class="col-6">
                            <input type="text" class="form-control form-control-sm border-radius-20 text-center" v-model="search" :placeholder="tt('search')" v-on:keyup="filter"/>
                        </div>
                        <div class="col text-right">
                            <a v-if="authUserPermission['kpi-mcr-creation-export']" :href="apiUrl + 'KpiMcrCreation/export?token=' + token" class="btn btn-sm btn-outline-dark">
                                {{ tt('export_as_excel') }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="table-responsive" style="height: 500px">
                    <table class="table" v-if="!onLoad.table">
                        <thead>
                            <tr>
                                <th class="text-center" colspan="9">{{ date }}</th>
                            </tr>
                            <tr>
                                <th>{{ tt('company_code') }}</th>
                                <th>{{ tt('name') }}</th>
                                <th>{{ tt('role') }}</th>
                                <th class="text-center">{{ tt('new') }}</th>
                                <th class="text-center">{{ tt('approved') }}</th>
                                <th class="text-center">{{ tt('rejected') }}</th>
                                <th class="text-center">{{ tt('revised') }}</th>
                                <th class="text-center">{{ tt('total_mcr') }}</th>
                                <th class="text-center">{{ tt('total_item_mcr') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in table.data">
                                <td>{{row.company_code}}</td>
                                <td>{{row.username}}</td>
                                <td>
                                    <span v-for="item in row.roles"><span>{{item.name}}</span><br></span>
                                </td>
                                <td class="text-center">{{row.draft}}</td>
                                <td class="text-center">{{row.approved}}</td>
                                <td class="text-center">{{row.rejected}}</td>
                                <td class="text-center">{{row.revised}}</td>
                                <td class="text-center">{{row.total_mcr}}</td>
                                <td class="text-center">{{row.total_item_mcr}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <page-loading v-else/>
                </div>
            </div>
        </div>
    </div>
    <noaccess v-else/>
</template>
<script>
	import {mapState} from "vuex";
	import Api from '@/helpers/api';
	import kpiMcrCreation from '@/services/dashboard/kpiMcrCreationDashboard.service';
	import config from '@/configs/config';

	export default {
		data() {
			return {
				date: '',
				table: {
					data: []
				},
				onLoad: {
					table: true
				},
				apiUrl: config.apiUrl,
                search : '',
				token: localStorage.getItem('token')
			}
		},
		computed: {
			...mapState({
				authUserPermission :state => state.auth.userPermission
			}),
		},
		mounted() {
			const today = new Date();
			this.date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
			this.get();
		},
		methods: {
			filter() {
                this.onLoad.table = true;

				clearTimeout(this.loadTimeout);
				this.loadTimeout = setTimeout(() => {
					this.get(this.search);
				}, 100);
			},
			get(param = '') {
				let context = this;
				Api(context, kpiMcrCreation.get({search: param})).onSuccess(function(response) {
					context.table.data = response.data.data.companies.data;
					context.onLoad.table = false;
				}).onError(function(error) {
					context.table.data = [];
					context.onLoad.table = false;
				}).call()
			},
		}
	};
</script>
